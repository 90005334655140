require('es6-promise').polyfill();
import 'nodelist-foreach-polyfill';
import GLightbox from '../node_modules/glightbox/dist/js/glightbox'; 
import Swiper from '../node_modules/swiper/swiper-bundle';
import accordion from './modules/accordeon';
import tabs from './modules/tabs';
import menu from './modules/menu';
import modal from './modules/modal';

window.addEventListener('DOMContentLoaded', () => { 
  // menu('.sidebar-arrow', '.sidebar', 'active');
  try {
    tabs('.item-head', '.item-content', '.item-headers', 'active');
  } catch (error) {}
  try {
    tabs('.joint-select__item', '.joint-content', '.joint-select', 'active');
  } catch (error) {}
  // modal('[data-modal]', '.modal');
  try {
    accordion('.products-menu__title');
  } catch (error) {}
  try {
    const linksPage = document.querySelectorAll('.info-head'),
        linkPage = window.location.href;
    function activeLink() {
      linksPage.forEach(link => {
        const linkHref = link.getAttribute('href');
        if (linkHref == linkPage) {
          link.classList.add('active');
        }
      });
    }
    activeLink();
  } catch (error) {}
  try {
    const heroSlider = new Swiper('.hero', {
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '#next',
        prevEl: '#prev',
      },
    });
  } catch (error) {}
  try {
    const galleryThumbs = new Swiper('.item-slider__thumb', {
      spaceBetween: 44,
      slidesPerView: 3,
      watchSlidesVisibility: true,
      watchSlidesProgress: true
    });
    const gallerySlider = new Swiper('.item-slider__big', {
      slidesPerView: 1,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 60,
      centeredSlides: true,
      thumbs: {
        swiper: galleryThumbs
      }
    });
  } catch (error) {}
  try { 
    function goodsSlider(parent, prev, next){
      const productsSlider = new Swiper(parent, {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          prevEl: prev,
          nextEl: next,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        },
      });
    }
    goodsSlider('#products_1', '#prev_1', '#next_1');
    goodsSlider('#products_2', '#prev_2', '#next_2');
  } catch (error) {}
  
});