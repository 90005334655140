function accordeon(triggersSelector) {
  const btns = document.querySelectorAll(triggersSelector);
  btns.forEach(btn => {
      btn.addEventListener('click', function() {
        console.log(btn);
          this.classList.toggle('active-style');
          this.nextElementSibling.classList.toggle('active-content');

          if (this.classList.contains('active-style')) {
              this.nextElementSibling.style.maxHeight = "fit-content";
          } else {
              this.nextElementSibling.style.maxHeight = '0px';
          }
      });
  }); 
  };

  export default accordeon;  